@use 'src/app/core/styles/style' as design-system;
@import '@angular/cdk/overlay-prebuilt.css';
@import '/src/app/core/styles/variables/variables';
:root {
  @include design-system.setup();
}

html,
body {
  height: 100%;
  font-family: 'Barlow';
}

body {
  font-size: 16px;
}

.invisible {
  visibility: hidden;
}

* {
  --font-size-12: 12px;
  --font-size-14: 14px;
  --container-height: 100%;
  --font: 14px;
  --font-10: 10px;
  --padding-bottom-16: 16px;
  --border-radius-4px: 4px;
  --font-weigh-700: 700;
  --width-50: 50%;
  --padding-16: 16px;
  --padding-28: 28px;
  --padding-btm-0: 12px 12px 0;
  --padding-12: 12px;
  --padding-16-0: 16px 0;
  --padding-0-16: 0 16px;
  --font-family: 'Barlow', sans-serif;
  --flex-1: flex: 1;
  --padding-top-12: 12px 0px;
  --padding-top-4: 4px;
  --padding-right-72: 72px;

  margin: 0;
  box-sizing: border-box;
  font-family: 'Barlow', sans-serif;

  .p-component {
    .p-overlay-content {
      margin-top: 8px;
      border-radius: 4px;
      box-shadow: 0 10px 15px 0 var(--grey-150);

      .p-dropdown-items {
        border: 1px solid var(--grey-150);
        border-radius: var(--border-radius-4px);
        background: var(--grey-25);

        .p-element .p-dropdown-item {
          padding: 8px 12px;
          background: var(--grey-25);

          &:hover {
            background-color: var(--green-5);
            border: 1px solid var(--green-30);
          }
        }

        .p-dropdown-item.p-highlight {
          color: var(--black-50);
          background-color: var(--grey-75);
        }
      }
    }
  }

  .center-flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  section {
    display: flex;
    flex-direction: column;
    background-color: var(--grey-125);
    height: 100%;
  }

  .step-master {
    grid-area: header;
    height: 85px;
    background-color: var(--black-100);
    border-radius: 12px;
    margin: 16px 16px 0;
  }

  .challenges,
  .input-solutions,
  .available-solutions,
  .review-selected-solutions,
  .diagnosis-summary-view,
  .input-infos,
  .required-action {
    height: 78vh;
  }

  .available-solutions {
    background-color: var(--grey-25);
  }

  .input-infos {
    padding: 20px;
  }

  .select-challenge-container,
  .select-solution-container,
  .view-summary-container,
  .required-actions-container {
    display: grid;
    grid-template-areas: 'header header';
    gap: 16px;
    padding: 16px;
  }

  .select-challenge-container {
    grid-template-columns: 2fr 1fr;
  }

  .select-solution-container {
    grid-template-columns: 0.8fr 2fr;
  }

  .view-summary-container,
  .required-actions-container {
    grid-template-areas: 'header header header';
    grid-template-columns: 1.75fr 1.3fr 3fr;
  }

  .bg-w-25 {
    background-color: var(--white-25);
  }

  .bdr-8 {
    border-radius: 8px;
  }

  .solution {
    padding: var(--padding-16);

    &-empty {
      grid-area: center;
      text-align: center;
      height: 25vh;
      padding-top: 48px;
      font-size: var(--font);

      .no-data {
        padding-bottom: 4px;
      }
    }
  }

  .clear-all-wrapper {
    width: var(--width-50);
    text-align: end;
    padding-bottom: var(--padding-28);
  }

  /* Responsive Styles */
  /* Small Devices (Modern smartphones, iPhone X, Galaxy S) */
  /* Extra Small Devices (Old small phones, iPhone SE) */

  @media only screen and (max-width: 1400px) {
    .select-challenge-container,
    .select-solution-container,
    .view-summary-container,
    .required-actions-container {
      padding: var(--font-size-12);
      display: block;
    }

    .view-summary-container,
    .required-actions-container {
      display: revert;
    }

    .review-selected-solutions,
    .input-infos {
      margin-bottom: 16px;
    }

    .challenges,
    .input-solutions,
    .available-solutions,
    .review-selected-solutions,
    .diagnosis-summary-view,
    .input-infos,
    .required-action {
      height: auto;
      width: 100%;
      margin-bottom: 12px;
    }

    .selected-challenge {
      height: auto;
    }

    @media (max-width: 700px) or (max-height: 700px) {
      .overflow-hidden {
        overflow-y: auto;
      }
    }
  }

  /* Laptop Screens*/
  @media (min-width: 1401px) and (max-width: 2000px) {
    @media (max-height: 750px) {
      .challenges,
      .input-solutions,
      .available-solutions,
      .review-selected-solutions,
      .diagnosis-summary-view,
      .input-infos,
      .required-action {
        height: 65vh;
      }
    }

    @media (min-height: 751px) and (max-height: 800px) {
      .challenges,
      .input-solutions,
      .available-solutions,
      .review-selected-solutions,
      .diagnosis-summary-view,
      .input-infos,
      .required-action {
        height: 69vh;
      }
    }

    @media (min-height: 800px) and (max-height: 851px) {
      .challenges,
      .input-solutions,
      .available-solutions,
      .review-selected-solutions,
      .diagnosis-summary-view,
      .input-infos,
      .required-action {
        height: 71vh;
      }
    }

    @media (min-height: 850px) and (max-height: 960px) {
      .challenges,
      .input-solutions,
      .available-solutions,
      .review-selected-solutions,
      .diagnosis-summary-view,
      .input-infos,
      .required-action {
        height: 74vh;
      }
    }

    @media (min-height: 961px) and (max-height: 1040px) {
      .challenges,
      .input-solutions,
      .available-solutions,
      .review-selected-solutions,
      .diagnosis-summary-view,
      .input-infos,
      .required-action {
        height: 76vh;
      }
    }
  }
}

.avatar-user {
  font-size: 14px;
  background-color: $color-white-primary-01;
  border: 1px solid $color-black-primary-01;
  cursor: pointer;
}

form {
  position: relative;
  label {
    font-size: 12px;
    &.required::after {
      content: '*';
      font-size: 12px;
      color: #c71a2f;
      position: relative;
    }
  }
}

.p-datatable-reorderable-row-handle.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
