@mixin setup($color-map: null) {
  @if ($color-map == null) {
    $color-map: ();
  }

  $colors: $color-map;

  @each $color-key, $color-steps in $colors {
    @each $step-key, $step-value in $color-steps {
      --#{$color-key}-#{$step-key}: #{$step-value};
    }
  }
}
