$font-primary: 14px;

$color-white: #fff;
$color-primary: #c71a2f;

$color-white-primary-01: #f7f8f9;
$color-white-primary-02: #c9cfd4;
$color-white-primary-03: #e8eced;
$color-white-primary-04: #e0e3e6;
$color-white-primary-04-hover: #c1c3c4;
$color-white-primary-05: #d8dadc;

$color-prey-primary-01: #607d8b;
$color-prey-primary-02: #3b4957;
$color-prey-primary-03: #818f99;

$color-black-primary-01: #141d27;
$color-black-primary-02: #2b3847;
$color-black-primary-02-hover: #3b4c5f;

$color-red-primary-01: #c8102e;

$color-icon-history: #23629c;
$color-blue-primary-01: #3275b8;

$color-success-bg: #32b877;

$color-yellow-primary-01: #d9b100;

$color-step-border: #c21228;
$color-step-bg: #e7a0a9;
